import React from 'react'
import App, { AppProps } from 'next/app'
import {
  CSSReset,
  theme,
  ThemeProvider,
  NProgress,
  BackendProvider,
  CarouselStyle,
} from '@thirstycamel/ui'
import { ReactQueryDevtools } from 'react-query-devtools'
import { StoreProvider } from 'easy-peasy'
import mixpanel from 'mixpanel-browser'
import { parseCookies, destroyCookie } from 'nookies'
import { NextPageContext } from 'next'
import Router from 'next/router'
import * as Sentry from '@sentry/node'
import dynamic from 'next/dynamic'

import { backend, mutation, humpclub, humpclubMutation } from '../utils/backend'
import withRedux from '../utils/withRedux'
import GlobalStyle from './_style'
import ModalRoot from '../components/ModalRoot'
import { makeStore } from '../store/store'
import { ReactQueryCacheProvider, QueryCache } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { createClient, Provider } from 'urql'
import MediaQueryProvider from '../components/MediaQueryProvider'
import { GoogleAnalyticsProvider } from '../components/GoogleAnalyticsProvider'
import { GTMPageView } from '../utils/google-tag-manager'
import { pageview } from '../utils/gtag'
import UserEntity from '@thirsty-camel/hump-club/src/modules/user/user.entity'

const ReactTooltip = dynamic(() => import('react-tooltip'), { ssr: false })

mixpanel.init('0ec4890755e2ced0c095e3a48a58903e')

const DONT_OPEN_STORE_PICKER_ON_PATHNAMES = [
  '/delivery',
  '/store-locator',
  '/privacy-policy',
  '/terms-conditions',
  '/page/[slug]',
  '/login',
  '/register',
  '/forgot-password',
  '/forgot-password/[...slug]',
  '/offer-unlock',
]

const queryCache = new QueryCache()

let geoip = undefined
let geoipLazy = undefined
let requestIp = undefined
let requestIpLazy = undefined

if (typeof window === 'undefined') {
  geoipLazy = import('geoip-lite')
  requestIpLazy = import('request-ip')
}

export type Context = NextPageContext & {
  store: ReturnType<typeof makeStore>
}

type TCPageProps = AppProps & {
  ctx: Context
}

const client = createClient({
  url: process.browser ? '/api/cms' : 'http://localhost:3000/api/cms',
})

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://d9b3f7db0bfc4e08bf462734e44a5ac0@o456981.ingest.sentry.io/5565147',
})

//@ts-ignore
class MyApp extends App<TCPageProps> {
  static async getInitialProps({ Component, ctx }: TCPageProps) {
    let pageProps = {}

    const { selected_store, cart_id, address, humpclub_token, force_region, over18 } = parseCookies(
      ctx,
    )

    const promises = []

    const storeState = ctx.store.getState()

    let user: UserEntity | void

    /* Run this first & foremost as some of the other calls rely on it. */
    if (humpclub_token && !storeState.auth.user) {
      user = await this.fetchUser(ctx, humpclub_token)
    }

    if (cart_id && cart_id !== 'null' && !storeState.cart.cart) {
      promises.push(this.fetchCart(ctx, cart_id))
    }

    if (
      !storeState.navigation.categories ||
      !storeState.navigation.categories.length ||
      !storeState.navigation.productLists ||
      !storeState.navigation.productLists.length
    ) {
      const region = storeState?.store?.selectedStore?.region || storeState?.location?.region

      promises.push(this.fetchNavigation(ctx, region))
    }

    if (selected_store && !storeState.store.selectedStore) {
      promises.push(this.fetchStore(ctx, selected_store))
    }

    if (address) {
      promises.push(this.setAddress(ctx, address))
    }

    promises.push(this.setOver18(ctx, over18 === 'true' || !!user))

    /* Ensure this is only run on the server when there's no address or store set. */
    if (typeof window === 'undefined' && !address && !selected_store) {
      promises.push(this.fetchIP(ctx, ctx.res, force_region))
    }

    /* If we're on the server and no store is selected, show a subtle store picker. */
    if (
      typeof window === 'undefined' &&
      !selected_store &&
      !DONT_OPEN_STORE_PICKER_ON_PATHNAMES.includes(ctx.pathname)
    ) {
      promises.push(ctx.store.dispatch.store.openPopout({ isSubtle: true }))
    }

    /* Optimise the promises by letting run simultaneously. */
    await Promise.all(promises)

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return { pageProps }
  }

  private static setAddress = async (ctx: Context, address: string) => {
    if (!address || typeof address !== 'string') return
    await ctx.store.dispatch.location.setAddress(address)
  }

  private static setOver18 = async (ctx: Context, over18?: boolean) => {
    ctx.store.dispatch.over18.setOver18(over18)
  }

  private static fetchNavigation = async (ctx: Context, region?: string) => {
    try {
      await ctx.store.dispatch.navigation.fetchCategories()

      await ctx.store.dispatch.navigation.fetchProductLists(region)
    } catch (e) {
      // Ignore error
    }
  }

  private static fetchStore = async (ctx: Context, selected_store: string) => {
    /* Fetch the currently selected store, which is set in the user's cookies. */
    try {
      await ctx.store.dispatch.store.fetchStore(selected_store)
    } catch (error) {
      console.warn(error.message)
      /* If the store could not fetch for whatever reason, clear the cookie. */
      destroyCookie(ctx, 'selected_store', { path: '/' })
    }
  }

  private static fetchUser = async (ctx: Context, humpclubToken: string) => {
    await ctx.store.dispatch.auth.setToken({ accessToken: humpclubToken })

    try {
      return ctx.store.dispatch.auth.getUser()
    } catch (e) {
      // Ignore error
    }
  }

  private static fetchCart = async (ctx: Context, cart_id: string) => {
    /* Fetch the currently user's cart, which is set in the user's cookies. */
    try {
      await ctx.store.dispatch.cart.fetchCart(cart_id)
    } catch (error) {
      console.warn(error.message)
      /* If the cart could not fetch for whatever reason, clear the cookie. */
      destroyCookie(ctx, 'cart_id', { path: '/' })
    }
  }

  private static fetchIP = async (ctx: Context, res, forcedRegion) => {
    if (!requestIp || !geoip) {
      ;[requestIp, geoip] = await Promise.all([requestIpLazy, geoipLazy])
    }

    const ip = requestIp.default.getClientIp(ctx.req)
    const result = geoip.default.lookup(ip)

    let region = forcedRegion || result?.region || 'VIC' // Default to VIC

    const validRegions = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT']

    if (!validRegions.includes(region)) {
      region = 'VIC'
    }

    ctx.store.dispatch.location.fetchRegionSuccess(region)
  }

  componentDidMount() {
    Router.events.on('routeChangeComplete', this.handleRouteChange)
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.handleRouteChange)
  }

  handleRouteChange = (url: string) => {
    /* Google Tag Manager */
    GTMPageView(url)
    /* Google Analytics */
    pageview(url)
  }

  render() {
    // @ts-ignore
    const { Component, pageProps, store, err } = this.props

    return (
      <>
        <NProgress />
        <StoreProvider store={store}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <Provider value={client}>
              <Hydrate state={pageProps.dehydratedState}>
                <ReactQueryDevtools />
                <BackendProvider
                  query={backend}
                  mutation={mutation}
                  humpclubQuery={humpclub}
                  humpclubMutation={humpclubMutation}
                >
                  <GoogleAnalyticsProvider>
                    <MediaQueryProvider>
                      <ThemeProvider theme={theme}>
                        <CSSReset />
                        <GlobalStyle />
                        <CarouselStyle />
                        <ModalRoot />
                        <ReactTooltip />

                        <Component {...pageProps} err={err} />
                      </ThemeProvider>
                    </MediaQueryProvider>
                  </GoogleAnalyticsProvider>
                </BackendProvider>
              </Hydrate>
            </Provider>
          </ReactQueryCacheProvider>
        </StoreProvider>
      </>
    )
  }
}

export default withRedux(MyApp)
